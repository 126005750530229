import { InjectionKey, Ref, ref, toRaw } from 'vue'
import {
  modalController,
  onIonViewDidEnter,
  onIonViewDidLeave,
  onIonViewWillEnter,
  onIonViewWillLeave,
} from '@ionic/vue'

import { useStore } from '@/store'
import router from '@/router'

export const ModalWrapper: InjectionKey<Ref<HTMLElement>> = Symbol('ModalWrapper')
export const useModal = async (story: any, wrapper: any) => {
  const store = useStore()
  const topOffset =
    parseInt(getComputedStyle(wrapper.value.$el).getPropertyValue('--ion-safe-area-top')) ?? 0
  const modal = await modalController.create({
    component: toRaw(story.view),
    componentProps: { data: story, title: story.title, topOffset },
    showBackdrop: false,
    canDismiss: true,
    mode: 'ios',
    // presentingElement: wrapper.value.$el,
  })

  store.modalOpened++
  // window.location.hash = story.slug && `#${story.slug}`
  await modal.present()
  await modal.onWillDismiss()
  // window.location.hash = ''
  store.modalOpened--
}

export const useInverted = () => {
  const store = useStore()
  const stop = ref(false)
  onIonViewWillEnter(() => {
    stop.value = true
  })

  onIonViewWillEnter(() => {
    // console.log('onIonViewWillEnter', true, stop.value)
    store.menuInverted = true
  })

  onIonViewWillLeave(() => {
    // console.log('onIonViewWillLeave', false, stop.value)
    store.menuInverted = false
  })

  onIonViewDidLeave(() => {
    stop.value = false
  })
}
