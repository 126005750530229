import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { SplashScreen } from '@capacitor/splash-screen'
import { Device } from '@capacitor/device'

import { useStore } from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/Homepage.vue'),
    meta: {
      inverted: true,
    },
  },
  {
    path: '/artists',
    component: () => import('@/views/Artists.vue'),
  },
  {
    path: '/schedule',
    component: () => import('@/views/Schedule.vue'),
  },
  {
    path: '/map',
    component: () => import('@/views/Map.vue'),
    meta: {
      inverted: true,
    },
  },
  {
    path: '/artists/:id',
    component: () => import('@/modals/ArtistDetail.vue'),
  },
  {
    path: '/pages/:id',
    component: () => import('@/modals/PageDetail.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(async () => {
  const store = useStore()
  await store.loadStories(store.language)

  if (!store.platform) {
    const info = await Device.getInfo()
    store.platform = info.platform
  }

  if (store.splashHidden || store.platform === 'web') return

  await new Promise((resolve) => setTimeout(resolve, 1000))
  await SplashScreen.hide({
    fadeOutDuration: 200,
  })
  store.splashHidden = true
})

router.beforeResolve(async (to, from, next) => {
  const store = useStore()
  // store.menuInverted = !!to.meta.inverted
  await new Promise((resolve) => setTimeout(resolve, 100))
  next(!store.preventBack)
  store.preventBack = false
})

export default router
